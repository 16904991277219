ul{
  color:black;
}
.link{
  color:black;
  text-decoration: none;
}
.link:hover{
  color:black;
}
.link:after {
  content:'';
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: 0;
  left: 0;
  background-color: black;
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.5s;
}

.link:hover::before, .link:hover::after {
  transform: scaleX(1);
}
.wrapper{
  position: relative;
  display: block;
}
h1{
  font-size: 40px;
  font-weight: 800;
}
h3{
  font-family: serif;
}